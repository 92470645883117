import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import Base from '../../_config/base';

class Slider extends Base {
  constructor(el) {
    super(el);
    const slider = el.querySelector('.swiper');

    const sliderOpt = {
      // configure Swiper to use modules
      modules: [Navigation, Pagination],
      // centeredSlides: true,
      effect: 'fade',
      speed: 500,
      // longSwipes: true,
      breakpoints: {
        // when window width is >= 320px
        320: {
          spaceBetween: 8,
          slidesPerView: 1.08,
        },
        // when window width is >= 768px
        768: {
          spaceBetween: 16,
          slidesPerView: 2.1,
        },
        // when window width is >= 1024px
        1024: {
          spaceBetween: 24,
          slidesPerView: 'auto',
        },
        // when window width is >= 1440px
        1440: {
          spaceBetween: 32,
          slidesPerView: 'auto',
        },
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };

    // eslint-disable-next-line
    const swiper = new Swiper(slider, sliderOpt);
  }
}

Slider.className = 'Slider';
export default Slider;
