import Base from '../../_config/base';

class Accordion extends Base {
  constructor(el) {
    super(el);
    const allItems = [...el.querySelectorAll('.accordion__item')];

    allItems.forEach((item) => { item.onclick = () => { item.classList.toggle('accordion__open'); }; });
  }
}

Accordion.className = 'Accordion';
export default Accordion;
