import Base from '../../_config/base';

class Location extends Base {
  constructor(el) {
    super(el);

    this.config = {
      togglerSelector: 'location__nav-item',
      blockSelector: 'location__innerContent',
      descriptionSelector: 'location__description',
      distanceBtn: '.distanceBtn',
      pointWrapper: '.location__points',
      pointBtn: '.location__points_item',
      distanceTable: '.location__distances',
      openClass: 'active',
    };

    this.buttons = this.$el.getElementsByClassName(this.config.togglerSelector);
    this.description = document.getElementsByClassName(this.config.descriptionSelector);
    this.distanceBtn = document.querySelectorAll(this.config.distanceBtn);
    this.pointWrapper = this.$el.querySelector(this.config.pointWrapper);
    this.distanceTable = document.querySelectorAll(this.config.distanceTable);

    [...this.buttons].forEach((btn) => {
      btn.addEventListener('click', this.triggerToggle.bind(this, btn));
    });

    [...this.distanceBtn].forEach((btn) => {
      btn.onclick = () => {
        [...this.distanceTable].forEach((table) => {
          table.classList.toggle(this.config.openClass);
          // if (table.classList.contains(this.config.openClass)) {
          //   btn.getElementsByTagName('span')[0].innerText = 'DISTANZEN AUSBLENDEN';
          // } else {
          //   btn.getElementsByTagName('span')[0].innerText = 'DISTANZEN ANZEIGEN';
          // }
        });
      };
    });
  }

  triggerToggle(activeBtn) {
    if (activeBtn.classList.contains(this.config.openClass)) return;

    [...this.description, this.pointWrapper].forEach((block) => {
      block.classList.toggle(this.config.openClass);
    });

    activeBtn.classList.add(this.config.openClass);
  }
}

Location.className = 'Location';
export default Location;
