/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
import { Loader } from '@googlemaps/js-api-loader';
import { setActive } from '../../_config/utils';
import Base from '../../_config/base';

class Map extends Base {
  constructor(el) {
    super(el);

    this.config = {
      togglerSelector: 'location__nav-item',
      blockSelector: 'location__innerContent',
      distanceBtn: '.distanceBtn',
      pointBtn: '.location__points_item',
      openClass: 'active',
    };

    this.buttons = this.$el.getElementsByClassName(this.config.togglerSelector);
    this.pointBtns = this.$el.querySelectorAll(this.config.pointBtn);
    this.pins = this.$el.getElementsByClassName('location__pin');

    this.pinsData = Array.from(this.pins).map((pin) => ({
      title: pin.getAttribute('data-title'),
      lat: pin.getAttribute('data-lat'),
      lng: pin.getAttribute('data-long'),
      type: pin.getAttribute('data-type'),
    }));

    this.markers = [];
    this.pinsData.push({
      title: 'Vitus areal',
      lat: '47.48798534720759',
      lng: '8.706101045362972',
      type: 'pin',
    });

    // const locations = [
    //   ['Vitus areal', 47.48798534720759, 8.706101045362972, 'pin'],
    // ];

    const loader = new Loader({
      // apiKey: 'AIzaSyCiJFtIAtuuxxYJrOz6bDXcusynIy_eqJg',
      apiKey: 'AIzaSyDMctRX9CcP9ahFykNkjTKgTAYCYObQ5iM',
      version: 'weekly',
      libraries: ['places'],
    });

    const mapStyle = {
      center: {
        lat: 47.48798534720759,
        lng: 8.706101045362972,
      },
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      styles: [
        {
          featureType: 'all',
          elementType: 'all',
          stylers: [{
            saturation: '-100',
          },
          {
            lightness: '0',
          },
          {
            gamma: '0.65',
          },
          ],
        },
        {
          elementType: 'labels.icon',
          stylers: [{
            visibility: 'off',
          }],
        },
        {
          featureType: 'all',
          elementType: 'labels.text.stroke',
          stylers: [{
            visibility: 'off',
          }],
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [{
            visibility: 'off',
          }],
        },
      ],
    };

    // eslint-disable-next-line prefer-arrow-callback
    loader.load().then((google) => {
      this.google = google;
      this.map = new google.maps.Map(document.getElementById('map'), {
        zoom: 10.3,
        ...mapStyle,
      });

      this.setMarkers(this.map, google, this.pinsData);
      this.setMarkersVisibility(false);

      // give each marker a category and show only the ones that are selected in the filter
      [...this.pointBtns].forEach((point) => {
        point.addEventListener('click', () => {
          const category = point.getAttribute('data-category');
          this.infoWindow.close();

          if (point.classList.contains('active')) {
            this.markers.forEach((currentMarker) => {
              currentMarker.setVisible(true);
            });
            point.classList.remove('active');
          } else {
            setActive(point);
            this.markers.forEach((currentMarker) => {
              const isCategoryEqualToActive = currentMarker.category === category || currentMarker.category === 'pin';
              currentMarker.setVisible(isCategoryEqualToActive);
            });
          }
        });
      });
    });

    // Micro / Macro buttons switcher
    [...this.buttons].forEach((btn, index) => {
      btn.onclick = () => {
        setActive(btn);

        if (index === 0) {
          // MACRO
          this.map.setZoom(10.3);
          this.setMarkersVisibility(false);
        } else {
          // MICRO
          this.map.setZoom(14.6);
          this.setMarkersVisibility(true);
        }
      };
    });
  }

  // Function to set the visibility of the marker depends on micro/macro view
  setMarkersVisibility(isViewMicro) {
    this.markers.forEach((marker) => {
      marker.category === 'pin'
        ? marker.setVisible(true)
        : !isViewMicro
          ? marker.setVisible(false)
          : marker.setVisible(true);
    });
  }

  // Function to create the markers array on the map and set the category to each marker
  setMarkers(gmap, googlemap, locations) {
    this.infoWindow = new googlemap.maps.InfoWindow();

    for (let i = 0; i < locations.length; i += 1) {
      // const location = locations[i];

      const marker = new googlemap.maps.Marker({
        position: {
          lat: +locations[i].lat,
          lng: +locations[i].lng,
        },
        map: gmap,
        icon: new googlemap.maps.MarkerImage(
          `./assets/images/${locations[i].type}.svg`,
          null,
          new googlemap.maps.Point(0, 0),
        ),
        // shape,
        visible: false,
        category: locations[i].type,
        title: `<a href="https://www.google.com/maps/search/?api=1&query=${locations[i].title}" target="_blank">${locations[i].title}</a>`,
      });

      this.markers.push(marker);
      // Add info window to each marker
      marker.addListener('click', () => {
        this.infoWindow.close();
        this.infoWindow.setContent(marker.getTitle());
        this.infoWindow.open(marker.getMap(), marker);
      });
    }
  }
}

Map.className = 'Map';
export default Map;
