/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import mixitup from 'mixitup';
import mixitupMultifilter from '../../mixitup/mixitup-multifilter';
import Base from '../../_config/base';
import { toggleClass, setActive } from '../../_config/utils';

mixitup.use(mixitupMultifilter);

const config = {
  animation: {
    effects: 'fade scale stagger(50ms)',
  },
  multifilter: {
    enable: true,
  },
  selectors: {
    target: '[data-ref~="target"]',
  },
  controls: {
    enable: true,
    toggleDefault: 'all',
  },
  debug: {
    enable: true,
  },
};

const containerEl = document.querySelector('.filteredContainer');

let mixer;
if (containerEl) {
  mixer = mixitup(containerEl, config);
}

class Partnerslist extends Base {
  constructor(el) {
    super(el);

    this.list = el.querySelectorAll('.partnersList__item');
    this.description = el.querySelectorAll('.partnersList__item_description');

    this.filterTrigger = el.querySelectorAll('.filter__container');
    this.list = document.querySelectorAll('.partnersList__item');
    this.searchField = document.getElementById('searchField');
    this.optionList = document.querySelectorAll('.select__radio__wrapper input[type="radio"]');
    const dropdownMainInput = document.querySelector('.dropdown__main div');
    const dropdownMain = el.querySelector('.dropdown__main');
    // const dropdownContainer = el.querySelector('.select__dropdown--container');

    this.resetButton = document.getElementById('filterReset');

    // Set maximum description length
    const numberOfCarachters = 100;

    [...this.description].forEach((block) => {
      const numberOfChildren = block.getElementsByTagName('*:not(br)').length;
      if (numberOfChildren === 0) {
        if (block.innerText.length > numberOfCarachters) {
          block.innerText = `${block.innerText.slice(0, numberOfCarachters)} ...`;
        }
      } else {
        let charachterCount = 0;
        for (let i = 0; i < numberOfChildren; i += 1) {
          if (charachterCount >= numberOfCarachters) block.children[i].innerText = '';
          if (block.children[i].innerText.length + charachterCount > numberOfCarachters) {
            block.children[i].innerText = `${block.children[i].innerText.slice(0, numberOfCarachters - charachterCount)} ...`;
            charachterCount += block.children[i].innerText.length;
          } else {
            charachterCount += block.children[i].innerText.length;
          }
        }
      }
    });

    let keyupTimeout;

    const filterByString = (searchValue) => {
      if (searchValue) {
        // Use an attribute wildcard selector to check for matches
        // eslint-disable-next-line prefer-template
        mixer.filter(`[resource-data*="${searchValue}"], [data-area*="${searchValue}"], [data-title*="${searchValue}"]`);
      } else {
        // If no searchValue, treat as filter('all')
        mixer.filter('all');
      }
    };

    this.resetButton?.addEventListener('click', (e) => {
      e.preventDefault();
      mixer.filter('all');
      this.searchField.value = '';
    });

    if (this.searchField) {
      this.searchField?.addEventListener('keyup', (e) => {
        let searchValue;
        // Very basic throttling to prevent mixer thrashing. Only search
        // once 300ms has passed since the last keyup event
        // eslint-disable-next-line no-use-before-define
        clearTimeout(keyupTimeout);
        keyupTimeout = setTimeout(() => {
          if (e.target.value.length < 3) {
          // If the input value is less than 3 characters, don't send
            searchValue = '';
          } else {
            // If user searches with search field => select input is set to initial value
            [...this.optionList].forEach((option) => {
              if (option.getAttribute('id') === 'option1') {
                dropdownMainInput.innerText = option.value;
                dropdownMain?.querySelector('label')?.classList.add('selected');
                setActive(option.closest('.select__radio__wrapper'));
              }
            });
            searchValue = e.target.value.toLowerCase();
          }
          filterByString(searchValue);
        }, 300);
      });
    }
  }
}

Partnerslist.className = 'Partnerslist';
export default Partnerslist;
