import Base from '../../_config/base';
import { toggleClass, setActive } from '../../_config/utils';

class Select extends Base {
  constructor(el) {
    super(el);
    const dropdownMain = document.querySelectorAll('.dropdown__main');
    // const dropdownMainInput = el.querySelectorAll('.dropdown__main div');
    // const dropdownContainer = el.querySelectorAll('.select__dropdown--container');
    // const optionList = document.querySelectorAll('.select__radio__wrapper input[type="radio"]');
    const textArea = document.querySelector('#message');
    const searchField = document.querySelector('#searchField');
    const selectAreaInput = document.querySelector('.select__area select');

    textArea?.addEventListener('input', () => {
      textArea.style.height = `${textArea.scrollHeight}px`;
    }, false);

    // if (dropdownMain !== null) {
    dropdownMain.forEach((dropdownMainItem) => {
      dropdownMainItem.addEventListener('click', () => {
        const dropdownContainer = dropdownMainItem.parentElement.querySelector('.select__dropdown--container');
        dropdownContainer.classList.remove('d-none');
        toggleClass(dropdownMainItem, 'active');
      });

      const optionList = dropdownMainItem.parentElement.querySelectorAll('.select__radio__wrapper input[type="radio"]');
      [...optionList].forEach((option) => {
        option.addEventListener('change', () => {
          const parentDropdownMainInput = option.closest('.select__dropdown').querySelector('.dropdown__main .input-field');
          parentDropdownMainInput.innerText = option.value;
          setActive(option.closest('.select__radio__wrapper'));
          const dropdownContainer = option.closest('.select__dropdown--container');
          const dropdownMainValue = option.closest('.select__dropdown').querySelector('.dropdown__main');
          dropdownContainer.classList.add('d-none');
          toggleClass(dropdownMainValue, 'active');
          searchField.value = '';
        });
      });
    });
    // }

    selectAreaInput.addEventListener('change', () => {
      const activeValue = selectAreaInput.value;
      const options = selectAreaInput.querySelectorAll('option');
      let minValue = 0;
      let maxValue = 0;
      options.forEach((option) => {
        if (option.value === activeValue) {
          minValue = option.getAttribute('data-input-min');
          maxValue = option.getAttribute('data-input-max');
          selectAreaInput.setAttribute('data-input-min', minValue);
          selectAreaInput.setAttribute('data-input-max', maxValue);
        }
      });
    });
  }
}

Select.className = 'Select';
export default Select;
