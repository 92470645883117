import { toggleClass } from '../../_config/utils';
import Base from '../../_config/base';

class Form extends Base {
  constructor(el) {
    super(el);

    const message = el.querySelectorAll('.contactForm__message');
    const hidedBox = el.querySelector('.contactForm__optionen');
    const openCheckbox = el.querySelector('#fromRequest');

    openCheckbox.onclick = () => {
      // console.log('openCheckbox', openCheckbox);
      // console.log('hidedBox', hidedBox);
      toggleClass(hidedBox, 'd-none');
    };

    if (message[0]) {
      setTimeout(() => {
        message[0].classList.add('hide');
      }, 3500);
    }
  }
}

Form.className = 'Form';
export default Form;
