/* eslint-disable no-useless-return */
import { toggleClass } from '../../_config/utils';
import Base from '../../_config/base';

class Popup extends Base {
  constructor(el) {
    super(el);

    const linkTrigger = document.querySelector('.popup-open');
    const closePopup = el.querySelector('.popup-close');

    const popup = el;

    linkTrigger.onclick = () => toggleClass(popup, 'popup-show');
    closePopup.onclick = () => toggleClass(popup, 'popup-show');
  }
}

Popup.className = 'Popup';
export default Popup;
