/* eslint-disable prefer-template */
import Base from '../../_config/base';

class Range extends Base {
  constructor(el) {
    super(el);

    const rangeOne = el.querySelector('input[name="rangeOne"]');
    const rangeTwo = el.querySelector('input[name="rangeTwo"]');
    const inclRange = el.querySelector('#incl-range');
    const rangeValue = el.querySelector('.range-value');
    // console.log('inclRange', inclRange);
    const updateView = (element) => {
      // if (element.getAttribute('name') === 'rangeOne') {
      //   outputOne.innerHTML = element.value;
      //   outputOne.style.left = (element.value / element.getAttribute('max')) * 100 + '%';
      // } else {
      //   outputTwo.style.left = (element.value / element.getAttribute('max')) * 100 + '%';
      //   outputTwo.innerHTML = element.value;
      // }

      if (parseInt(rangeOne.value, 10) > parseInt(rangeTwo.value, 10)) {
        inclRange.style.width = ((rangeOne.value - rangeTwo.value) / element.getAttribute('max')) * 100 + '%';
        inclRange.style.left = (rangeTwo.value / element.getAttribute('max')) * 100 + '%';
        rangeValue.innerHTML = `${rangeTwo.value} - ${rangeOne.value}`;
      } else {
        inclRange.style.width = ((rangeTwo.value - rangeOne.value) / element.getAttribute('max')) * 100 + '%';
        inclRange.style.left = (rangeOne.value / element.getAttribute('max')) * 100 + '%';
        rangeValue.innerHTML = `${rangeOne.value} - ${rangeTwo.value}`;
      }
    };

    window.onload = () => {
      updateView(rangeOne);
      updateView(rangeTwo);
    };

    rangeOne.addEventListener('input', () => {
      updateView(rangeOne);
    });

    rangeTwo.addEventListener('input', () => {
      updateView(rangeTwo);
    });
  }
}

Range.className = 'Range';
export default Range;
