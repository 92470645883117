import { setActive } from '../../_config/utils';
import Base from '../../_config/base';

class Mappopup extends Base {
  constructor(el) {
    super(el);

    const openTrigger = document.querySelectorAll('.map-open');
    const closePopupBtn = document.querySelectorAll('.popup-close-btn');
    const body = document.querySelector('#body');
    const popup = document.getElementById('mapPopup');
    const filtersBodies = document.querySelectorAll('.areaMap__filter_body');
    this.showHideImageOnCardButtons = document.querySelectorAll('.popup-btn-pins');
    this.headerTabs = document.querySelectorAll('.areaMap__filter_header-tab');
    this.buildingsCards = document.querySelectorAll('.buildings__wrapper');
    this.tenantsCards = document.querySelectorAll('.tenants__wrapper');
    this.rangeSlider = document.querySelector('.range-slider');
    this.openFilterButton = document.querySelector('#showFilter');
    this.closeFilterButton = document.querySelector('#closeFilter');
    // this.closeBuildingCardButton = document.querySelectorAll('.hideBuildingCard');
    // this.closeTenantsCardButton = document.querySelectorAll('.hideTenantCard');
    this.filter = document.querySelector('#areaMap__filter');
    this.canvas = document.querySelector('#mapCanvas');
    this.pinShowHideSwitcher = document.querySelector('#showPins');

    if (window.innerWidth >= 1024) {
      this.showHideImageOnCardButtons.forEach((button) => {
        const card = button.closest('.buildings__wrapper');
        const imageWrapper = card?.querySelector('.buildings__image_wrapper');
        const contentWrapper = card?.querySelector('.buildings__inner_body');
        contentWrapper?.classList.add('active');
        imageWrapper?.classList.add('active');
        button.classList.add('active');
      });
    }

    // console.log('openTrigger', openTrigger);

    if (this.rangeSlider) {
      if (this.rangeSlider.querySelector('.range-markers-double')) {
        this.rangeSlider.querySelector('.range-markers-double').style.width = 'calc(100% - 1.2rem)';
      }
      this.rangeSlider.querySelector('.range-label').style.fontSize = '1.2rem';
      this.rangeSlider.querySelector('.range-value').style.fontSize = '1.2rem';
    }

    this.openFilterButton.addEventListener('click', () => {
      this.filter.classList.add('active');
    });

    this.pinShowHideSwitcher.addEventListener('click', () => {
      const pins = this.pinShowHideSwitcher.querySelectorAll('.popup-btn-pins-item');
      const bages = document.querySelectorAll('.objectBage');
      pins.forEach((pinIcon) => {
        pinIcon.classList.toggle('active');
      });
      bages.forEach((bage) => {
        bage.classList.toggle('active');
      });
      if (this.pinShowHideSwitcher.getAttribute('data-pin') === 'close') {
        this.pinShowHideSwitcher.setAttribute('data-pin', 'open');
      } else {
        this.pinShowHideSwitcher.setAttribute('data-pin', 'close');
      }
    });

    this.showHideImageOnCardButtons.forEach((showHideImageBtn) => {
      showHideImageBtn.addEventListener('click', () => {
        const card = showHideImageBtn.closest('.buildings__wrapper');
        const imageWrapper = card.querySelector('.buildings__image_wrapper');
        const contentWrapper = card.querySelector('.buildings__inner_body');
        contentWrapper.classList.toggle('active');
        imageWrapper.classList.toggle('active');
        showHideImageBtn.classList.toggle('active');
      });
    });

    this.closeFilterButton.addEventListener('click', () => this.filter.classList.remove('active'));
    // Close building description card
    // this.closeBuildingCardButton.forEach((closeButton) => {
    //   closeButton.addEventListener('click', () => {
    //     // this.bages = document.querySelectorAll('.objectBage');
    //     this.buildingsCards.forEach((card) => card.classList.add('d-none'));
    //     // this.bages.forEach((bage) => {
    //     //   if (bage.getAttribute('data-name') === closeButton.getAttribute('data-cardName')) {
    //     //     bage.classList.remove('active');
    //     //   }
    //     // });
    //   });
    // });

    // this.closeTenantsCardButton.forEach((closeButton) => {
    //   closeButton.addEventListener('click', () => {
    //     this.tenantsCards.forEach((card) => card.classList.add('d-none'));
    //     // eslint-disable-next-line prefer-destructuring
    //     window.history.replaceState('', document.title, window.location.pathname);
    //     // window.location.href = window.location.href.split('#')[0];
    //   });
    // });

    [...openTrigger].forEach((openPopupBtn) => {
      openPopupBtn.addEventListener('click', () => {
        // this.canvas.classList.add('active');
        popup.classList.add('popup-show');
        body.classList.add('overflow-hidden');
      });
    });

    this.headerTabs.forEach((tab, index) => {
      if (index < 2) {
        tab.addEventListener('click', () => {
          setActive(tab);
          filtersBodies.forEach((filterBody) => {
            if (filterBody.classList.contains(tab.getAttribute('data-tab'))) {
              filterBody.classList.add('active');
            } else {
              filterBody.classList.remove('active');
            }
          });
        });
      }
    });

    closePopupBtn.forEach((closeBtn) => {
      closeBtn.onclick = () => {
        popup.classList.remove('popup-show');
        body.classList.remove('overflow-hidden');
        // toggleClass(popup, 'popup-show');
        // toggleClass(body, 'overflow-hidden');
        this.filter.classList.remove('active');
      };
    });
  }
}

Mappopup.className = 'Mappopup';
export default Mappopup;
