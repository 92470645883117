/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-loop-func */
/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-globals */
/* eslint-disable import/no-extraneous-dependencies */
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
// import { DragControls } from 'three/examples/jsm/controls/DragControls';
import gsap from 'gsap';
import Base from '../../_config/base';

const gqlBuildingsQuery = `{
  buildingsEntries {
    ... on buildings_default_Entry {
      building {
        ... on building_BlockType {
          bageName
          buildingId
          availableSpacesInDetails {
            ... on availableSpacesInDetails_availableSpacesInDetails_BlockType {
              spaceDetails {
                ... on spaceDetails_BlockType {
                  area
                  usageType
                }
              }
            }
          }
        }
      }
    }
  }
}`;

const supplementaryBages = [
  {
    type: 'fountain',
    position: { x: -100, y: 0, z: -110 },
  },
  {
    type: 'public-bus',
    position: { x: -150, y: 0, z: -200 },
  },
  {
    type: 'public-bus',
    position: { x: 190, y: 0, z: -40 },
  },
];

const bages = [
  {
    title: 'MH',
    position: { x: -10, y: 0, z: -130 },
    name: '01_MH',
    usage: 'usage-all usage-stock usage-offices usage-trade',
  },
  {
    title: 'BT40',
    position: { x: -140, y: 10, z: -70 },
    name: '02_BT40',
    usage: 'usage-all usage-stock usage-offices usage-trade',
  },
  {
    title: 'WH38',
    position: { x: -170, y: 0, z: -40 },
    name: '03_WH38',
    usage: 'usage-all usage-stock usage-offices usage-trade',
  },
  {
    title: 'HBG',
    position: { x: -90, y: 0, z: -60 },
    name: '04_HBG',
    usage: 'usage-all usage-stock usage-offices',
  },
  {
    title: 'BT55',
    position: { x: -40, y: 0, z: -30 },
    name: '05_BT55',
    usage: 'usage-all usage-stock usage-offices usage-trade',
  },
  {
    title: 'WH60',
    position: { x: 20, y: 0, z: -10 },
    name: '06_WH60',
    usage: 'usage-all usage-stock usage-offices usage-trade',
  },
  {
    title: 'PAV',
    position: { x: 30, y: 0, z: 60 },
    name: '07_PAV',
  },
  {
    title: 'WUH',
    position: { x: 100, y: 0, z: -120 },
    name: '08_WUH',
    usage: 'usage-all usage-stock usage-offices usage-trade',
  },
  {
    title: 'WH50',
    position: { x: 170, y: 0, z: 0 },
    name: '09_WH50',
    usage: 'usage-all usage-stock usage-trade',
  },
  {
    title: 'WH58',
    position: { x: 115, y: 0, z: 20 },
    name: '10_WH58',
    usage: 'usage-all usage-stock usage-offices usage-trade',
  },
  {
    title: 'WH54',
    position: { x: 80, y: 0, z: 0 },
    name: '11_WH64',
    usage: 'usage-all usage-stock',
  },
  {
    title: 'WH89',
    position: { x: 120, y: 0, z: 100 },
    name: '12_WH89',
    usage: 'usage-all',
  },
  {
    title: 'HLH',
    position: { x: 110, y: 0, z: 150 },
    name: '13_HLH',
  },
  {
    title: 'FL',
    position: { x: 160, y: 0, z: 150 },
    name: '14_FL',
    usage: 'usage-all usage-stock',
  },

];

const tenantBages = [
  {
    title: 'Allreal',
    position: { x: -20, y: 0, z: -130 },
    name: 'allreal',
    // type: 'type-service',
    type: '01_MH',
  },
  {
    title: 'M. Tanner AG',
    position: { x: 10, y: 0, z: -130 },
    name: 'tanner',
    // type: 'type-service',
    type: '01_MH',
  },
  {
    title: 'ISS',
    position: { x: 40, y: 0, z: -120 },
    name: 'iss',
    // type: 'type-service',
    type: '01_MH',
  },
  {
    title: 'Flux Mobility AG',
    position: { x: -40, y: 0, z: -80 },
    name: 'flux',
    // type: 'type-service',
    type: '01_MH',
  },
  {
    title: 'Protectas SA',
    position: { x: -10, y: 0, z: -80 },
    name: 'protectas',
    // type: 'type-service',
    type: '01_MH',
  },
  {
    title: 'Worn Again',
    position: { x: 20, y: 0, z: -80 },
    name: 'worn-again',
    type: '01_MH',
  },
  {
    title: 'Sulzer',
    position: { x: 40, y: 0, z: -80 },
    name: 'sulzer',
    type: '01_MH',
  },
  {
    title: 'Microliving ',
    position: { x: 155, y: 10, z: -30 },
    name: 'microliving ',
    type: '09_WH50',
  },
  {
    title: 'Rowell',
    position: { x: 170, y: 10, z: -20 },
    name: 'rowell',
    type: '09_WH50',
  },
  {
    title: 'Top Design AG',
    position: { x: 155, y: 10, z: -10 },
    name: 'top-design-ag-2',
    type: '09_WH50',
  },
  {
    title: 'Oels',
    position: { x: 170, y: 10, z: 0 },
    name: 'oels',
    type: '09_WH50',
  },
  {
    title: 'CKW',
    position: { x: 155, y: 10, z: 10 },
    name: 'ckw',
    type: '09_WH50',
  },
  {
    title: 'Ananas CrossFit',
    position: { x: 170, y: 10, z: 20 },
    name: 'ananas',
    type: '09_WH50',
  },
  {
    title: 'Altix AG',
    position: { x: 155, y: 10, z: 30 },
    name: 'altix',
    type: '09_WH50',
  },
  {
    title: 'ETH Zurich',
    position: { x: 170, y: 10, z: 40 },
    name: 'eth-zurich',
    type: '09_WH50',
  },
  {
    title: 'Wolfram Industries',
    position: { x: 155, y: 10, z: 50 },
    name: 'wolfram-industries',
    type: '09_WH50',
  },
  {
    title: 'Modulix',
    position: { x: 170, y: 10, z: 60 },
    name: 'modulix',
    type: '09_WH50',
  },
  {
    title: 'Wärmepunkt ',
    position: { x: 155, y: 10, z: 70 },
    name: 'waermepunkt',
    type: '09_WH50',
  },
  {
    title: 'Büro für Ereignisse ',
    position: { x: 165, y: 10, z: 70 },
    name: 'buero-fuer-ereignisse',
    type: '09_WH50',
  },
  {
    title: 'Home of Innovation',
    position: { x: 80, y: 10, z: 0 },
    name: 'home-of-innovation',
    type: '11_WH64',
  },
  {
    title: 'CKW',
    position: { x: 80, y: 10, z: 30 },
    name: 'ckw',
    type: '11_WH64',
  },
  {
    title: 'TC Werkstoff',
    position: { x: 80, y: 10, z: 60 },
    name: 'tc-werkstoff',
    type: '11_WH64',
  },
  {
    title: 'Flux Mobility AG',
    position: { x: 100, y: 10, z: 20 },
    name: 'flux',
    type: '10_WH58',
  },
  {
    title: 'iDQ AG',
    position: { x: 115, y: 10, z: 20 },
    name: 'idq-ag',
    type: '10_WH58',
  },
  {
    title: "Ivi's Glanzfabrik",
    position: { x: 100, y: 10, z: 70 },
    name: 'ivis-glanzfabrik',
    type: '10_WH58',
  },
  {
    title: 'Planzer',
    position: { x: 115, y: 10, z: 70 },
    name: 'planzer',
    type: '10_WH58',
  },
  {
    title: 'ETH Zurich',
    position: { x: 135, y: 10, z: 70 },
    name: 'eth-zurich',
    type: '10_WH58',
  },
  {
    title: 'Planzer',
    position: { x: 120, y: 10, z: 100 },
    name: 'planzer',
    type: '12_WH89',
  },
  {
    title: 'BestDrive',
    position: { x: 90, y: 10, z: -120 },
    name: 'bestdrive',
    type: '08_WUH',
  },
  {
    title: 'iDQ AG',
    position: { x: 120, y: 10, z: -120 },
    name: 'idq-ag',
    type: '08_WUH',
  },
  {
    title: 'Craftlab',
    position: { x: 90, y: 10, z: -90 },
    name: 'craftlab-gmbh',
    type: '08_WUH',
  },
  {
    title: 'CKW',
    position: { x: 150, y: 10, z: 150 },
    name: 'ckw',
    type: '14_FL',
  },

  {
    title: "JJ's",
    position: { x: 20, y: 10, z: 110 },
    name: 'jjs',
    type: '18_Restaurant',
  },
  
];

let createdBages = [];
let selectedObjects = {};
let keyupTimeout;

class Areamap extends Base {
  constructor(el) {
    super(el);

    const mapExecute = () => {
      gsap.registerPlugin();

      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;

      const yPositionAdjustment = windowHeight < 800 ? 250 : 150;
      const zoomVariable = windowWidth < 768 ? 500 : windowWidth < 1024 ? 200 : 100;
      this.highliteColor = '0xca8dff';
      this.highliteColorAll = '0x87c763';
      this.highliteColorOffices = '0xbfb573';
      this.highliteColorStock = '0x7abfcc';
      this.highliteColorTrade = '0xc98cff';
      this.initBuildingColor = '0xC8E2B5';
      this.notAvailableBuildingColor = '0x7D8F8B';
      this.bridgesColor = '0xbababa';
      this.bageRenderingTime = 0;

      const openPopupButtons = document.querySelectorAll('.map-open');
      const canvas = document.getElementById('mapCanvas');
      const mapCanvasWrapper = document.getElementById('mapCanvasWrapper');
      this.usageButtons = el.querySelectorAll('.button-group__button input');
      this.selectInput = el.querySelector('.select__buildings');
      this.popupComponent = document.querySelector('#mapPopup');
      // this.selectButtons = el.querySelectorAll('.select__buildings input[name="select-buildings"]');
      this.selectButtons = el.querySelector('.select__buildings select');
      // console.log('this.selectButtons', this.selectButtons);
      this.selectTenantInput = el.querySelector('.select__tenants');
      this.selectTenantButtons = el.querySelector('.select__tenants select');
      this.selectAreaInput = el.querySelector('.select__area select');
      // console.log('this.selectAreaInput', this.selectAreaInput);
      this.searchField = el.querySelector('.input-search');
      this.resetButton = el.querySelector('#filterReset');
      this.tenantList = el.querySelector('.areaMap__filter_select-container');
      // this.tenantSingle = el.querySelector('.areaMap__filter_tenants-container');
      this.emptyTenantElem = document.querySelector('.tenantList__item_empty');
      this.tenantListBySearch = el.querySelector('.areaMap__filter_search-container');
      // this.rangeOne = el.querySelector('input[name="rangeOne"]');
      // this.rangeTwo = el.querySelector('input[name="rangeTwo"]');
      const buildingsCards = document.querySelectorAll('.buildings__owerview');
      this.buildingsData = [];
      this.closeBuildingCardButton = document.querySelectorAll('.hideBuildingCard');
      const tenantsCards = document.querySelectorAll('.tenants__wrapper');
      this.closeTenantsCardButton = document.querySelectorAll('.hideTenantCard');

      const loadingMessage = document.getElementById('loading');

      const scene = new THREE.Scene();
      scene.background = new THREE.Color(0xFFFFFF);
      let model;
      let camera;
      let renderer;

      const loader = new GLTFLoader();
      const draco = new DRACOLoader();
      draco.setDecoderPath('https://vitus-areal.ch/draco/');
      draco.preload();
      draco.setDecoderConfig({ type: 'js' });
      loader.setDRACOLoader(draco);

      openPopupButtons.forEach((openPopupBtn) => {
        openPopupBtn.addEventListener('click', () => {
          if (el.getAttribute('data-opened') === 'true') {
            const hrefData = openPopupBtn.getAttribute('href');
            if (hrefData) {
              const hash = hrefData.slice(hrefData.indexOf('#') + 1);
              showAllByHash(scene, makeBageInstance, renderer, camera, canvas, hash, tenantsCards);
            }
            return;
          }
        
          loader.load('/assets/images/Rieter_Areal_241017_v01.gltf', (gltf) => {
            model = gltf.scene;

            model.position.setX(-200);
            model.position.setY(-450 + yPositionAdjustment);
            model.position.setZ(-220);

            scene.add(model);
            scene.background.r = 0.8;
            scene.background.g = 0.8;
            scene.background.b = 0.8;

            const currentHash = window.location.hash;

            // Initial buiding highlite
            // Initial bages rendering
            if (currentHash) {
              initialBuildingHighliht(scene, currentHash.slice(1), tenantsCards);
              initialBagesRendering(makeBageInstance, renderer, scene, camera, canvas, currentHash.slice(1));
            } else {
              initialBuildingHighliht(scene);
              initialBagesRendering(makeBageInstance, renderer, scene, camera, canvas);
            }

            // Hide loading message after model's loading and rendering
            loadingMessage.innerText = '';
            loadingMessage.style.display = 'none';

            // Add attribute to popup compotent to not render again map after second open
            this.popupComponent.setAttribute('data-opened', true);

            // Add to the map supplementary bages
            addSupplementaryBages(makeSupplementaryBageInstance, renderer, scene, camera, canvas);
            // Query buildings data and add rows by usage types
            fetch(`https://vitus-areal.ch/graphql?query=${gqlBuildingsQuery}`)
              .then((response) => response.text())
              .then((responceString) => {
                const buildingsEntries = JSON.parse(responceString);
                const buildingsArray = buildingsEntries.data.buildingsEntries;
                // console.log('buildingsArray', buildingsArray);
                return buildingsArray;
              })
              .then((buildingsArray) => {
                buildingsArray.forEach((buildingItem) => {
                  const spaces = buildingItem.building[0]?.availableSpacesInDetails[0]?.spaceDetails;
                  const buildingId = buildingItem.building[0].bageName;
                  this.buildingsData.push({
                    buildingName: buildingId,
                    buildingSpaces: spaces,
                  });
                  // console.log('buildingItem', buildingItem);
                  const buildingCard = [...buildingsCards]?.filter((card) => card.getAttribute('data-building') === buildingId)[0];
                  if (spaces) {
                    const stockAreas = spaces.filter((space) => space.usageType === 'stock');
                    const officesAreas = spaces.filter((space) => space.usageType === 'offices');
                    const tradeAreas = spaces.filter((space) => space.usageType === 'trade');
                    stockAreas?.sort((a, b) => a.area - b.area);
                    officesAreas?.sort((a, b) => a.area - b.area);
                    tradeAreas?.sort((a, b) => a.area - b.area);

                    const addAreaDataToRow = (dataArray, className) => {
                      if (dataArray.length) {
                        const buildingsDetailsRow = buildingCard.querySelector(`.${className}`);
                        buildingsDetailsRow.classList.remove('d-none');
                        const valueBlock = buildingsDetailsRow.querySelector('[data-area-value]');
                        if (dataArray.length > 1) {
                          valueBlock.innerText = `${dataArray[0].area}m² - ${dataArray[dataArray.length - 1].area}m²`;
                        } else {
                          valueBlock.innerText = `${dataArray[0].area}m²`;
                        }
                      }
                    };

                    addAreaDataToRow(stockAreas, 'buildings__details-stock');
                    addAreaDataToRow(officesAreas, 'buildings__details-offices');
                    addAreaDataToRow(tradeAreas, 'buildings__details-trade');
                  }
                });
              })
              // eslint-disable-next-line no-console
              .catch((error) => console.error(error));

            console.log('scene.children', scene.children);
            // console.log('scene', scene);

            for (let i = 0; i < scene.children[3].children.length; i += 1) {
              Listener('click', scene.children[3].children[i], (e, intersect) => {
                const { object } = intersect;
                console.log('intersect', object.name, object);
                if (object.parent.name.includes('building')) {
                  console.log('building', object.name, object);
                // const clonedMaterial = object.material.clone();
                // clonedMaterial.color.r = 0.5;
                // clonedMaterial.color.g = 0.64;
                // clonedMaterial.color.b = 0.2;
                // object.material = clonedMaterial;
                }
              });
            }
          }, (xhr) => {
            loadingMessage.style.zIndex = 21;

            if (xhr.loaded !== xhr.total) {
              // Show loading status
              loadingMessage.innerText = `${((xhr.loaded / xhr.total) * 100).toFixed(1)} % loaded`;
              el.setAttribute('data-opened', 'true');
            }
          }, (error) => {
            console.error(error);
          });

          const sizes = {
            width: window.innerWidth,
            height: window.innerHeight,
          };

          const light = new THREE.PointLight(0xffffff, 0.3);
          const amblight = new THREE.AmbientLight(0xffffff, 0.7);

          // const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0x080808, 0.6);
          light.position.set(0, 500, 200);
          light.power = 16;
          light.decay = 0;
          light.distance = Infinity;

          scene.add(light);
          // scene.add(hemisphereLight);
          scene.add(amblight);

          // const lightHelper = new THREE.PointLightHelper(light, 10);
          // scene.add(lightHelper);

          camera = new THREE.PerspectiveCamera(70, sizes.width / sizes.height, 1, 1500);

          const cameraInitPosition = [-5, 300 + zoomVariable, 250];
          moveCamera(cameraInitPosition);
          camera.lookAt(0, 0, 0);
          scene.add(camera);

          // const CameraHelper = new THREE.CameraHelper(camera);
          // scene.add(CameraHelper);

          renderer = new THREE.WebGLRenderer({ canvas, antialias: true });
          renderer.setSize(sizes.width, sizes.height);
          renderer.render(scene, camera);
          renderer.useLegacyLights = true;

          const orbitControls = new OrbitControls(camera, canvas);
          // const controls = new DragControls(scene?.children[3]?.children, camera, canvas);
          // controls.addEventListener('dragstart', (event) => {
          //   event.object.material.emissive.set(0xaaaaaa);
          // });

          orbitControls.enablePan = false;
          orbitControls.enableDamping = true;
          orbitControls.enableZoom = true;
          orbitControls.minPolarAngle = 0.4;
          orbitControls.maxPolarAngle = Math.PI / 2 - 0.9;
          orbitControls.minDistance = 400;
          orbitControls.maxDistance = 750;
          orbitControls.isDraggable = true;
          orbitControls.target = new THREE.Vector3(0, 0, 0);
          orbitControls.update();

          window.addEventListener('resize', () => {
            sizes.width = window.innerWidth;
            sizes.height = window.innerHeight;
            camera.updateProjectionMatrix();
            camera.aspect = sizes.width / sizes.height;
            renderer.setSize(sizes.width, sizes.height);
          });

          window.addEventListener('orientationchange', () => {
            sizes.width = window.innerWidth;
            sizes.height = window.innerHeight;
            camera.updateProjectionMatrix();
            camera.aspect = sizes.width / sizes.height;
            renderer.setSize(sizes.width, sizes.height);
          });

          screen.orientation.addEventListener('change', () => {
            sizes.width = window.innerWidth;
            sizes.height = window.innerHeight;
            camera.updateProjectionMatrix();
            camera.aspect = sizes.width / sizes.height;
            renderer.setSize(sizes.width, sizes.height);
          });

          // Filter by click on USAGE BUTTONS
          this.usageButtons.forEach((btn) => {
            btn.onclick = () => {
              // Check if button is not active => execute logic
              if (!btn.classList.contains('active')) {
                if (Object.keys(selectedObjects).length > 0) {
                  scene.traverse((child) => {
                    if (child.name in selectedObjects) {
                      child.children = selectedObjects[child.name][1].children;
                    }
                  });
                }
                // Remove active class from all buttons
                this.usageButtons.forEach((usageBtn) => {
                  usageBtn.classList.remove('active');
                  usageBtn.parentNode.classList.remove('active');
                });
                // Remove all bages from HTML
                removeAllBages();

                selectedObjects = {};
                initialBuildingHighliht(scene);

                const minValue = +this.selectAreaInput.getAttribute('data-input-min');
                const maxValue = +this.selectAreaInput.getAttribute('data-input-max');
                const btnUsageType = btn.getAttribute('data-usage');

                if (btnUsageType === 'usage-all') {
                  // Initial buiding highlite
                  // initialBuildingHighliht(scene);
                  // Initial bages rendering
                  // initialBagesRendering(makeBageInstance, renderer, scene, camera, canvas);

                  this.buildingsData?.forEach((buildingItem) => {
                    const buildingId = buildingItem.buildingName;
                    const buildingRows = buildingItem.buildingSpaces;

                    if (buildingRows) {
                      const even = (element) => +element.area > minValue && +element.area < maxValue;

                      if (buildingRows.some(even)) {
                        scene.traverse((child) => {
                          if (child.name.includes(buildingId)) {
                            renderBagesAndHighliteObjects(child, makeBageInstance, camera, canvas, scene, renderer, btnUsageType);
                          }
                        });
                      }
                    }
                  });
                } else {
                  // Filtering for buildings with the same usage data
                  this.buildingsData.forEach((buildingItem) => {
                    const buildingId = buildingItem.buildingName;
                    const buildingRows = buildingItem.buildingSpaces;
                    if (buildingRows) {
                      const even = (element) => btnUsageType.includes(element.usageType) && (+element.area > minValue && +element.area < maxValue);

                      if (buildingRows.some(even)) {
                        scene.traverse((child) => {
                          if (child.name.includes(buildingId)) {
                            renderBagesAndHighliteObjects(child, makeBageInstance, camera, canvas, scene, renderer, btnUsageType);
                          }
                        });
                      }
                    }
                  });
                }
                
                btn.classList.add('active');
                btn.parentNode.classList.add('active');
              }
            };
          });

          // Filter by range input
          // this.rangeOne.addEventListener('change', () => {
          //   setTimeout(() => {
          //     rangeInputHandler(makeBageInstance, camera, canvas, scene, renderer);
          //   }, 50);
          // });
          // this.rangeTwo.addEventListener('change', () => {
          //   setTimeout(() => {
          //     rangeInputHandler(makeBageInstance, camera, canvas, scene, renderer);
          //   }, 50);
          // });

          // Filter by search input 
          this.searchField.addEventListener('keyup', (e) => {
            let searchValue;
            clearTimeout(keyupTimeout);
            keyupTimeout = setTimeout(() => {
              if (e.target.value.length < 3) {
              // If the input value is less than 3 characters, don't send
                searchValue = '';
                this.selectInput.style.display = 'block';
              } else {
                // If user searches with search field => select input is set to initial value
                this.selectInput.style.display = 'none';
                searchValue = e.target.value.toLowerCase();
              }

              if (searchValue) {
                console.log('searchValue', searchValue);
                const name = searchValue.toLowerCase();
                const filterByName = true;
                setTenantBages(name, makeTenantBageInstance, renderer, scene, camera, canvas, filterByName);
                setTenantList(name, tenantsCards, filterByName);
              }
            }, 300);

            this.resetButton?.addEventListener('click', () => {
              this.searchField.value = '';
              this.tenantListBySearch.innerHTML = '';
              this.selectInput.style.display = 'block';
              removeAllBages();
            });
          });

          // Filter by select input (buildings)
          this.selectButtons.addEventListener('change', () => {
            const type = this.selectButtons.value;
            this.searchField.style.display = type !== 'all' ? 'none' : 'block';
            initialBuildingHighliht(scene);
            setTenantBages(type, makeTenantBageInstance, renderer, scene, camera, canvas);
            setTenantList(type, tenantsCards);
            if (type !== 'all') {
              scene.traverse((child) => {
                if (child.name.includes(type)) {
                  highliteOneBuilding(child);
                }
              });
              this.selectTenantInput.classList.add('d-none');
            } else {
              initialBuildingHighliht(scene);
              this.emptyTenantElem.classList.add('d-none');
              this.selectTenantInput.classList.remove('d-none');
            }
          });

          // Filter by select input (buildings)
          this.selectTenantButtons.addEventListener('change', () => {
            const type = this.selectTenantButtons.value;
            this.searchField.style.display = type !== 'all' ? 'none' : 'block';
            initialBuildingHighliht(scene);
            setTenantBagesWithName(type, makeTenantBageInstance, renderer, scene, camera, canvas);
            showSingleTenant(type, tenantsCards);
            if (type !== 'all') {
              this.selectInput.classList.add('d-none');
              this.tenantListBySearch.classList.remove('d-none');
            } else {
              initialBuildingHighliht(scene);
              this.selectInput.classList.remove('d-none');
              this.tenantListBySearch.classList.add('d-none');
              removeAllBages();
            }
          });

          // Filter by select input (areas)
          this.selectAreaInput.addEventListener('change', () => {
            removeAllBages();

            selectedObjects = {};
            initialBuildingHighliht(scene);

            const minValue = +this.selectAreaInput.getAttribute('data-input-min');
            const maxValue = +this.selectAreaInput.getAttribute('data-input-max');
            const activeBtn = [...this.usageButtons].filter((usageBtn) => usageBtn.classList.contains('active'))[0];
            const usageType = activeBtn.getAttribute('data-usage');
            if (usageType === 'usage-all') {
              this.buildingsData?.forEach((buildingItem) => {
                const buildingId = buildingItem.buildingName;
                const buildingRows = buildingItem.buildingSpaces;

                if (buildingRows) {
                  const even = (element) => +element.area > minValue && +element.area < maxValue;

                  if (buildingRows.some(even)) {
                    scene.traverse((child) => {
                      if (child.name.includes(buildingId)) {
                        renderBagesAndHighliteObjects(child, makeBageInstance, camera, canvas, scene, renderer, usageType);
                      }
                    });
                  }
                }
              });
            } else {
              // Filtering for buildings with the same usage data
              this.buildingsData.forEach((buildingItem) => {
                const buildingId = buildingItem.buildingName;
                const buildingRows = buildingItem.buildingSpaces;

                if (buildingRows) {
                  const even = (element) => usageType.includes(element.usageType) && (+element.area > minValue && +element.area < maxValue);

                  if (buildingRows.some(even)) {
                    scene.traverse((child) => {
                      if (child.name.includes(buildingId)) {
                        renderBagesAndHighliteObjects(child, makeBageInstance, camera, canvas, scene, renderer, usageType);
                      }
                    });
                  }
                }
              });
            }
          });

          this.closeBuildingCardButton.forEach((closeButton) => {
            closeButton.addEventListener('click', () => {
              buildingsCards.forEach((card) => card.classList.add('d-none'));
              moveCamera(cameraInitPosition);
              rotateCamera([0, 0, 0]);
            });
          });

          this.closeTenantsCardButton.forEach((closeButton) => {
            closeButton.addEventListener('click', () => {
              tenantsCards.forEach((card) => card.classList.add('d-none'));
              // eslint-disable-next-line prefer-destructuring
              window.history.replaceState('', document.title, window.location.pathname);
              // removeAllBages();
              moveCamera(cameraInitPosition);
              rotateCamera([0, 0, 0]);
            });
          });

          // eslint-disable-next-line no-unused-vars
          function makeBageInstance(geometry, color, position, name, bageColor) {
            const hexColor = typeof bageColor === 'string' ? bageColor.replace(/0x/g, '#') : '#CBC490';
            const cube = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial({ color }));
            scene.add(cube);
            cube.position.x = position.x;
            cube.position.y = position.y + yPositionAdjustment;
            cube.position.z = position.z;
            const elem = document.createElement('div');
            elem.classList.add('objectBage');
            elem.setAttribute('data-name', name);
            elem.textContent = name;
            elem.style.background = hexColor;
            elem.addEventListener('click', () => {
              buildingsCards?.forEach((buildingsCard) => {
                buildingsCard.classList.add('d-none');
                if (buildingsCard.getAttribute('data-building') === name) {
                  buildingsCard.classList.remove('d-none');
                  moveCamera([
                    position.x, position.y + 400, position.z + 200]);
                  rotateCamera([position.x, position.y, position.z]);
                }
              });
            }); 
            mapCanvasWrapper.appendChild(elem);
            return { cube, elem };
          }

          function makeTenantBageInstance(geometry, color, position, name, number, bageColor) {
            const hexColor = typeof bageColor === 'string' ? bageColor.replace(/0x/g, '#') : '#CBC490';
            const cube = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial({ color }));
            scene.add(cube);
            cube.position.x = position.x;
            cube.position.y = position.y + yPositionAdjustment;
            cube.position.z = position.z;
            const elem = document.createElement('div');
            elem.classList.add('objectBage');
            elem.setAttribute('data-name', name);
            elem.style.background = hexColor;
            elem.textContent = number;
            elem.addEventListener('click', () => {
              tenantsCards?.forEach((tenantCard) => {
                tenantCard.classList.add('d-none');
                if (tenantCard.getAttribute('data-tenant') === name) {
                  tenantCard.classList.remove('d-none');
                  moveCamera([
                    position.x, position.y + 400, position.z + 200]);
                  rotateCamera([position.x, position.y, position.z]);
                }
              });
              // console.log('clickTenant', name);
            }); 
            mapCanvasWrapper.appendChild(elem);
            return { cube, elem };
          }

          function makeSupplementaryBageInstance(geometry, color, position, name) {
            const cube = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial({ color }));
            scene.add(cube);
            cube.position.x = position.x;
            cube.position.y = position.y + yPositionAdjustment;
            cube.position.z = position.z;
            const elem = document.createElement('div');
            elem.classList.add('objectBage');
            elem.classList.add('objectBage__supplementary');
            elem.setAttribute('data-name', name);
            const image = document.createElement('img');
            image.setAttribute('src', `https://vitus-areal.ch/assets/images/${name}.svg`);
            elem.appendChild(image);
            mapCanvasWrapper.appendChild(elem);
            return { cube, elem };
          }

          function moveCamera(position) {
            const x = position[0];
            const y = position[1];
            const z = position[2];
            gsap.to(camera.position, {
              x,
              y,
              z,
              duration: 0.9,
            });
          }

          function rotateCamera(position) {
            const x = position[0];
            const y = position[1];
            const z = position[2];
            gsap.to(orbitControls.target, {
              x,
              y,
              z,
              duration: 0.9,
            });
            orbitControls.update();
          }

          function Listener(listener, mesh, callback) {
            // addMouseMesh();
            const objects = [mesh];
            const raycaster = new THREE.Raycaster();
            const mouse = { x: 0, y: 0 };
            renderer.domElement.addEventListener(listener, raycast, false);

            function raycast(e) {
              // console.log('event', e);
              mouse.x = (e.clientX / renderer.domElement.clientWidth) * 2 - 1;
              mouse.y = -(e.clientY / renderer.domElement.clientHeight) * 2 + 1;
              raycaster.setFromCamera(mouse, camera);
              const intersects = raycaster.intersectObjects(objects, true);
              // console.log('mouse', mouse);
              // console.log('intersects', intersects);
              if (intersects[0]) {
                callback(e, intersects[0]);
              }
            }
          }

          const loop = () => {
            orbitControls.update();
            renderer.render(scene, camera);
            window.requestAnimationFrame(loop);
          };

          loop();
        });
      });
    };
    
    mapExecute();

    // const highliteBuilding = (child) => {
    //   const cloneObject = child.parent.clone();
    //   selectedObjects[child.parent.name] = [child.parent.position.y, cloneObject];
    //   child.parent.traverse((cloneChild) => {
    //     if (cloneChild.type === 'Mesh') {
    //       const clonedMaterial = cloneChild.material.clone();
    //       clonedMaterial.color.setHex(this.highliteColor);
    //       cloneChild.material = clonedMaterial;
    //     }
    //   });
    // };

    const addSupplementaryBages = (makeSupplementaryBageInstance, renderer, scene, camera, canvas) => {
      const pinShowHideSwitcher = document.querySelector('#showPins');
      // const supplementaryBagesArray = [];
      supplementaryBages.forEach((bage) => {
        const labelItem = makeSupplementaryBageInstance(
          new THREE.SphereGeometry(0.1, 0.1, 0.1),
          0x44aa88,
          bage.position,
          bage.type,
        );
        createdBages.push(labelItem);
      });

      console.log('createdBages', createdBages);

      const tempV = new THREE.Vector3();
      const renderBages = () => {
        createdBages.forEach((labelItem) => {
          const { cube, elem } = labelItem;
          // get the position of the center of the cube
          cube.updateWorldMatrix(true, false);
          cube.getWorldPosition(tempV);
    
          // get the normalized screen coordinate of that position
          // x and y will be in the -1 to +1 range with x = -1 being
          // on the left and y = -1 being on the bottom
          tempV.project(camera);
    
          // convert the normalized position to CSS coordinates
          const x = (tempV.x * 0.5 + 0.5) * canvas.clientWidth;
          const y = (tempV.y * -0.5 + 0.5) * canvas.clientHeight;
    
          // move the elem to that position
          elem.style.transform = `translate(-50%, -50%) translate(${x}px,${y}px)`;
          // // Check pins button to show or hide bages
          if (pinShowHideSwitcher.getAttribute('data-pin') === 'open') {
            elem.classList.add('active');
          } else {
            elem.classList.remove('active');
          }
      
          renderer.render(scene, camera);
        });

        // eslint-disable-next-line no-undef
        clearTimeout(keyupTimeout);
        keyupTimeout = setTimeout(() => {
          requestAnimationFrame(renderBages);
        }, this.bageRenderingTime);
      };
      renderBages();
    };

    const highliteOneBuilding = (child, btnUsageType) => {
      const cloneObject = child.clone();
      selectedObjects[child.name] = [child.position.y, cloneObject];
      child.traverse((cloneChild) => {
        if (cloneChild.type === 'Mesh') {
          const clonedMaterial = cloneChild.material.clone();
          switch (btnUsageType) {
            case 'usage-all':
              clonedMaterial.color.setHex(this.highliteColorAll);
              break;
            case 'usage-offices':
              clonedMaterial.color.setHex(this.highliteColorOffices);
              break;
            case 'usage-stock':
              clonedMaterial.color.setHex(this.highliteColorStock);
              break;
            case 'usage-trade':
              clonedMaterial.color.setHex(this.highliteColorTrade);
              break;
            default:
              console.log(`Usage ${btnUsageType} is not defined`);
          }

          if (!btnUsageType) {
            clonedMaterial.color.setHex(this.highliteColorAll);
          }
          cloneChild.material = clonedMaterial;
        }
      });
    };

    const initialBuildingHighliht = (scene, hash, tenantsCards) => {
      // Add color to available buildings
      scene.traverse((child) => {
        if (child.name.includes('01_MH') 
          || child.name.includes('02_BT40') 
          || child.name.includes('03_WH38') 
          || child.name.includes('04_HBG') 
          || child.name.includes('05_BT55') 
          || child.name.includes('06_WH60')
          || child.name.includes('07_PAV') 
          || child.name.includes('08_WUH') 
          || child.name.includes('09_WH50') 
          || child.name.includes('10_WH58') 
          || child.name.includes('11_WH64') 
          || child.name.includes('12_WH89') 
          || child.name.includes('13_HLH') 
          || child.name.includes('14_FL') 
          || child.name.includes('15_Stadtwerke')) {
          child.traverse((cloneChild) => {
            if (cloneChild.type === 'Mesh') {
              const clonedMaterial = cloneChild.material.clone();
              clonedMaterial.color.setHex(this.initBuildingColor);
              cloneChild.material = clonedMaterial;
            }
          });
        }
      });
      // Add color to not available buldings
      scene.traverse((child) => {
        if (child.name.includes('Object_1003') 
          || child.name.includes('Object_1004') 
          || child.name.includes('16_Rieter_Campus')) {
          child.traverse((cloneChild) => {
            if (cloneChild.type === 'Mesh') {
              const clonedMaterial = cloneChild.material.clone();
              clonedMaterial.color.setHex(this.notAvailableBuildingColor);
              cloneChild.material = clonedMaterial;
            }
          });
        }
      });
      // Add color to briges
      scene.traverse((child) => {
        if (child.name.includes('swisstopo_Bridges')) {
          child.traverse((cloneChild) => {
            if (cloneChild.type === 'Mesh') {
              const clonedMaterial = cloneChild.material.clone();
              clonedMaterial.color.setHex(this.bridgesColor);
              cloneChild.material = clonedMaterial;
            }
          });
        }
      });
      
      if (hash) {
        // Add color to specific building depends on hash in url
        scene.traverse((child) => {
          if (child.name.includes(hash)) {
            child.parent.traverse((cloneChild) => {
              if (cloneChild.type === 'Mesh') {
                const clonedMaterial = cloneChild.material.clone();
                clonedMaterial.color.setHex(this.highliteColor);
                cloneChild.material = clonedMaterial;
              }
            });
          }
        });
        showTenantCard(hash, tenantsCards);
      }
    };

    const initialBagesRendering = (makeBageInstance, renderer, scene, camera, canvas, hash) => {
      const pinShowHideSwitcher = document.querySelector('#showPins');

      if (hash) {
        removeAllBages();
        const bageToShow = tenantBages.filter((item) => item.name === hash);
        if (bageToShow) {
          bageToShow.forEach((bage) => {
            const labelItem = makeBageInstance(
              new THREE.SphereGeometry(0.1, 0.1, 0.1),
              0x44aa88,
              bage.position,
              bage.title,
              this.highliteColorAll,
            );
            createdBages.push(labelItem);
          });
        }
      } else {
        bages.forEach((bage) => {
          const labelItem = makeBageInstance(
            new THREE.SphereGeometry(0.1, 0.1, 0.1),
            0x44aa88,
            bage.position,
            bage.title,
            this.highliteColorAll,
          );
          createdBages.push(labelItem);
        });
      }

      const tempV = new THREE.Vector3();

      const renderBages = () => {
        createdBages.forEach((labelItem) => {
          const { cube, elem } = labelItem;
          // get the position of the center of the cube
          cube.updateWorldMatrix(true, false);
          cube.getWorldPosition(tempV);
    
          // get the normalized screen coordinate of that position
          // x and y will be in the -1 to +1 range with x = -1 being
          // on the left and y = -1 being on the bottom
          tempV.project(camera);
    
          // convert the normalized position to CSS coordinates
          const x = (tempV.x * 0.5 + 0.5) * canvas.clientWidth;
          const y = (tempV.y * -0.5 + 0.5) * canvas.clientHeight;
    
          // move the elem to that position
          elem.style.transform = `translate(-50%, -50%) translate(${x}px,${y}px)`;
          // Check pins button to show or hide bages
          if (pinShowHideSwitcher.getAttribute('data-pin') === 'open') {
            elem.classList.add('active');
          } else {
            elem.classList.remove('active');
          }
      
          renderer.render(scene, camera);
        });

        // eslint-disable-next-line no-undef
        clearTimeout(keyupTimeout);
        keyupTimeout = setTimeout(() => {
          requestAnimationFrame(renderBages);
        }, this.bageRenderingTime);
      };
      renderBages();
    };

    const removeAllBages = () => {
      const existingBages = el.querySelectorAll('.objectBage');
      existingBages.forEach((bage) => {
        if (!bage.classList.contains('objectBage__supplementary')) {
          bage.parentNode.removeChild(bage);
        }
      });
      createdBages = createdBages.filter((bage) => bage.elem.classList.contains('objectBage__supplementary'));
    };

    const renderBagesAndHighliteObjects = (child, makeBageInstance, camera, canvas, scene, renderer, btnUsageType) => {
      const pinShowHideSwitcher = document.querySelector('#showPins');
      highliteOneBuilding(child, btnUsageType);
      let hichliteColor = this.highliteColorAll;
      switch (btnUsageType) {
        case 'usage-all':
          hichliteColor = this.highliteColorAll;
          break;
        case 'usage-offices':
          hichliteColor = this.highliteColorOffices;
          break;
        case 'usage-stock':
          hichliteColor = this.highliteColorStock;
          break;
        case 'usage-trade':
          hichliteColor = this.highliteColorTrade;
          break;
        default:
          console.log(`Usage ${btnUsageType} is not defined`);
      }

      bages.forEach((bage) => {
        // console.log()
        if (child.name.includes(bage.name)) {
          const labelItem = makeBageInstance(
            new THREE.SphereGeometry(0.1, 0.1, 0.1),
            0xca8dff,
            bage.position,
            bage.title,
            hichliteColor,
          );
          createdBages.push(labelItem);
        }
      });
      
      const tempV = new THREE.Vector3();
      const renderBages = () => {
        createdBages.forEach((labelItem) => {
          const { cube, elem } = labelItem;
          // get the position of the center of the cube
          cube.updateWorldMatrix(true, false);
          cube.getWorldPosition(tempV);
    
          // get the normalized screen coordinate of that position
          // x and y will be in the -1 to +1 range with x = -1 being
          // on the left and y = -1 being on the bottom
          tempV.project(camera);
    
          // convert the normalized position to CSS coordinates
          const x = (tempV.x * 0.5 + 0.5) * canvas.clientWidth;
          const y = (tempV.y * -0.5 + 0.5) * canvas.clientHeight;
    
          // move the elem to that position
          elem.style.transform = `translate(-50%, -50%) translate(${x}px,${y}px)`;
          // Check pins button to show or hide bages
          if (pinShowHideSwitcher.getAttribute('data-pin') === 'open') {
            elem.classList.add('active');
          } else {
            elem.classList.remove('active');
          }
      
          renderer.render(scene, camera);
        });
        
        // eslint-disable-next-line no-undef
        clearTimeout(keyupTimeout);
        keyupTimeout = setTimeout(() => {
          requestAnimationFrame(renderBages);
        }, this.bageRenderingTime);
      };
      clearTimeout(keyupTimeout);
      keyupTimeout = setTimeout(() => {
        requestAnimationFrame(renderBages);
      }, this.bageRenderingTime);
    };

    // const rangeInputHandler = (makeBageInstance, camera, canvas, scene, renderer) => {
    //   initialBuildingHighliht(scene);
    //   removeAllBages();
    //   scene.traverse((child) => {
    //     if (child.name.includes('space')) {
    //       let activeUsageButton;
    //       this.usageButtons.forEach((btn) => {
    //         if (btn.classList.contains('active')) {
    //           activeUsageButton = btn.getAttribute('data-usage');
    //         }
    //       });
    //       if (child.name.includes(activeUsageButton) || activeUsageButton === 'usage-all') {
    //         const availableSpace = +(child.name.slice(child.name.indexOf('space')).slice(5));
    //         // Added solution to changed lower and upper slider
    //         if (+(this.rangeTwo.value < this.rangeOne.value ? this.rangeTwo.value : this.rangeOne.value) 
    //           <= availableSpace && availableSpace 
    //           < +(this.rangeOne.value > this.rangeTwo.value ? this.rangeOne.value : this.rangeTwo.value)) {
    //           renderBagesAndHighliteObjects(child, makeBageInstance, camera, canvas, scene, renderer);
    //         }
    //       }
    //     }
    //   });
    // };

    const showTenantCard = (slug, tenantsCards) => {
      tenantsCards.forEach((card) => {
        if (card.getAttribute('data-tenant') === slug) {
          card.classList.remove('d-none');
        } else {
          card.classList.add('d-none');
        }
      });
    };

    const showAllByHash = (scene, makeBageInstance, renderer, camera, canvas, hash, tenantsCards) => {
      initialBuildingHighliht(scene, hash, tenantsCards);
      initialBagesRendering(makeBageInstance, renderer, scene, camera, canvas, hash);
    };

    const setTenantBages = (type, makeTenantBageInstance, renderer, scene, camera, canvas, filterByName) => {
      removeAllBages();
      const pinShowHideSwitcher = document.querySelector('#showPins');
      let tenantListLength = 0;
      tenantBages.forEach((bage) => {
        if (filterByName ? bage.name.includes(type) : bage.type.includes(type)) {
          tenantListLength += 1;
          const labelItem = makeTenantBageInstance(
            new THREE.SphereGeometry(0.1, 0.1, 0.1),
            0x44aa88,
            bage.position,
            bage.name,
            tenantListLength,
            this.highliteColorAll,
          );
          createdBages.push(labelItem);
        }
      });
      
      const tempV = new THREE.Vector3();
      const renderBages = () => {
        createdBages.forEach((labelItem) => {
          const { cube, elem } = labelItem;
          // get the position of the center of the cube
          cube.updateWorldMatrix(true, false);
          cube.getWorldPosition(tempV);
    
          // get the normalized screen coordinate of that position
          // x and y will be in the -1 to +1 range with x = -1 being
          // on the left and y = -1 being on the bottom
          tempV.project(camera);
    
          // convert the normalized position to CSS coordinates
          const x = (tempV.x * 0.5 + 0.5) * canvas.clientWidth;
          const y = (tempV.y * -0.5 + 0.5) * canvas.clientHeight;
    
          // move the elem to that position
          elem.style.transform = `translate(-50%, -50%) translate(${x}px,${y}px)`;
          // Check pins button to show or hide bages
          if (pinShowHideSwitcher.getAttribute('data-pin') === 'open') {
            elem.classList.add('active');
          } else {
            elem.classList.remove('active');
          }
      
          renderer.render(scene, camera);
        });
        
        // eslint-disable-next-line no-undef
        clearTimeout(keyupTimeout);
        keyupTimeout = setTimeout(() => {
          requestAnimationFrame(renderBages);
        }, this.bageRenderingTime);
      };
      clearTimeout(keyupTimeout);
      keyupTimeout = setTimeout(() => {
        requestAnimationFrame(renderBages);
      }, this.bageRenderingTime);
    };

    const setTenantList = (type, tenantsCards, filterByName) => {
      let tenantListLength = 0;
      this.tenantList.innerHTML = '';
      this.tenantListBySearch.innerHTML = '';
      tenantBages.forEach((bage) => {
        if (filterByName ? bage.name.includes(type) : bage.type.includes(type)) {
          const elem = document.createElement('div');
          const numberElem = document.createElement('div');
          const textElem = document.createElement('div');
          elem.classList.add('tenantList__item');
          elem.setAttribute('data-tenantName', bage.name);
          
          tenantListLength += 1;
          numberElem.textContent = tenantListLength;
          numberElem.classList.add('tenantList__item_number');
          textElem.textContent = bage.title;
          elem.appendChild(numberElem);
          elem.appendChild(textElem);
          if (filterByName) {
            this.tenantListBySearch.appendChild(elem);
          } else {
            this.tenantList.appendChild(elem);
          }

          elem.addEventListener('click', () => {
            tenantsCards?.forEach((tenantCard) => {
              if (tenantCard.getAttribute('data-tenant') === bage.name) {
                tenantCard.classList.remove('d-none');
              }
            });
            // console.log('clickTenant', name);
          }); 
        }
      });

      if (tenantListLength === 0) {
        this.emptyTenantElem.classList.remove('d-none');
      } else {
        this.emptyTenantElem.classList.add('d-none');
      }
    };

    const setTenantBagesWithName = (type, makeTenantBageInstance, renderer, scene, camera, canvas) => {
      removeAllBages();
      const pinShowHideSwitcher = document.querySelector('#showPins');
      tenantBages.forEach((bage) => {
        if (bage.name.includes(type)) {
          const labelItem = makeTenantBageInstance(
            new THREE.SphereGeometry(0.1, 0.1, 0.1),
            0x44aa88,
            bage.position,
            bage.name,
            bage.title,
            this.highliteColorAll,
          );
          createdBages.push(labelItem);
        }
      });
      
      const tempV = new THREE.Vector3();
      const renderBages = () => {
        createdBages.forEach((labelItem) => {
          const { cube, elem } = labelItem;
          // get the position of the center of the cube
          cube.updateWorldMatrix(true, false);
          cube.getWorldPosition(tempV);
    
          // get the normalized screen coordinate of that position
          // x and y will be in the -1 to +1 range with x = -1 being
          // on the left and y = -1 being on the bottom
          tempV.project(camera);
    
          // convert the normalized position to CSS coordinates
          const x = (tempV.x * 0.5 + 0.5) * canvas.clientWidth;
          const y = (tempV.y * -0.5 + 0.5) * canvas.clientHeight;
    
          // move the elem to that position
          elem.style.transform = `translate(-50%, -50%) translate(${x}px,${y}px)`;
          // Check pins button to show or hide bages
          if (pinShowHideSwitcher.getAttribute('data-pin') === 'open') {
            elem.classList.add('active');
          } else {
            elem.classList.remove('active');
          }
      
          renderer.render(scene, camera);
        });
        
        // eslint-disable-next-line no-undef
        clearTimeout(keyupTimeout);
        keyupTimeout = setTimeout(() => {
          requestAnimationFrame(renderBages);
        }, this.bageRenderingTime);
      };
      clearTimeout(keyupTimeout);
      keyupTimeout = setTimeout(() => {
        requestAnimationFrame(renderBages);
      }, this.bageRenderingTime);
    };

    const showSingleTenant = (type, tenantsCards) => {
      let tenantListLength = 0;
      this.tenantListBySearch.innerHTML = '';
      tenantBages.forEach((bage) => {
        if (bage.name.includes(type) && tenantListLength < 1) {
          tenantListLength += 1;
          const elem = document.createElement('div');
          elem.classList.add('tenantList__item');
          elem.setAttribute('data-tenantName', bage.name);
          
          elem.textContent = bage.title;
          this.tenantListBySearch.appendChild(elem);

          elem.addEventListener('click', () => {
            tenantsCards?.forEach((tenantCard) => {
              if (tenantCard.getAttribute('data-tenant') === bage.name) {
                tenantCard.classList.remove('d-none');
              }
            });
          }); 
        }
      });

      if (tenantListLength === 0) {
        this.emptyTenantElem.classList.remove('d-none');
      } else {
        this.emptyTenantElem.classList.add('d-none');
      }
    };
  }
}

Areamap.className = 'areamap';
export default Areamap;
